/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React from 'react'
import PropTypes from 'prop-types'
import { UIIcon, UILayout, UIText } from 'bora-material-ui'
import { find, propEq } from 'ramda'

import { contactFields as fields } from '../../components/ContactFormFields'
import { translate } from '../../../Common/Translator'

import { InputField, DropDownField } from '../../../../formComponents'
import messages from '../../../../consts/messages'

import { CustomerRole } from './CustomerRole'
import { FORM_MODE_PRIVATE } from './consts'
import { modernBrowser } from '../../../../utils/browserUtils'
import { fillContactDataForm, isReallyProduction, fillCompanyDataForm } from '../../../../utils/formFillinUtils'
import { getPhoneCode } from '../../../../services/utils'
import UIElements from '../../../../consts/UIElements'
import { isGavdos } from 'bora/src/utils/gavdosStyleUtils'
import { THEME } from 'bora-material-ui/themes'
import { getStyle } from '../../../../utils/liinilaevadThemeUtils'
import { getMaritimeStyle, getBluewaveStyle, isMaritime } from '../../../../utils/maritimeStyleUtils'
import moduleStyles from '../../../Modal/Modals.module.scss'
import ciStyles from './CustomerInfo.module.scss'
import cn from 'classnames'
import { includeOnlyCorrectSymbols } from '../../../../utils/validate'

const fillNameFields = (firstName, lastName) => {
  return (change) => {
    change('firstName', firstName)
    change('lastName', lastName)
  }
}

const fillAllFields = (firstName, lastName, phoneCode, phoneNumber, email) => {
  return (change) => {
    change('phoneCode', phoneCode)
    change('firstName', firstName)
    change('lastName', lastName)
    change('phoneNumber', phoneNumber)
    change('email', email)
  }
}

const xsStyles = {
  column: { column: true },
  titleWrapper: { margin: ' 0 0 15px' },
  title: { align: 'center' },
}

const CompanyItemTemplate = (props) => {
  if (!props) return null
  return (
    <UILayout height="44px" align="center">
      <UIText size={getStyle('18px', '15px')} color="#000000" text={props.title || props.value.title} />
    </UILayout>
  )
}

CompanyItemTemplate.propTypes = {
  value: PropTypes.object,
  title: PropTypes.string,
}

CompanyItemTemplate.defaultProps = {
  value: {},
  title: '',
}

const defaultPhoneCode = getPhoneCode(window.brandProps.theme)

export const fillButtonStyles = {
  marginRight: '20px',
  fontSize: 'x-small',
  width: '150px',
  background: 'darkorange',
  border: 0,
  color: 'white',
  cursor: 'pointer',
  padding: '5px',
}

const fillButtonMaritimeStyles = {
  margin: '0 0 0 20px',
  fontSize: 'x-small',
  width: '100px',
  background: 'darkorange',
  border: 0,
  color: 'white',
  cursor: 'pointer',
  padding: '5px',
  height: '25px',
}

class CustomerInfo extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedMode: 'private',
    }

    this.fillContact = this.fillContact.bind(this)
    this.fillCompanyContact = this.fillCompanyContact.bind(this)
  }

  componentDidMount() {
    const { guests, change, isLogged, customer = {} } = this.props
    if (isLogged && isMaritime) {
      const { firstName = '', lastName = '', email = '', phone = {}, phones = [] } = customer
      const { phoneNumber = '', intlCode = '' } = phones[0] ? phones[0] : phone
      fillAllFields(firstName, lastName, intlCode, phoneNumber, email)(change)
    } else {
      const arePassengersInMaritimeReservation = guests && guests.some((guest) => guest.firstName && guest.lastName)
      if (isMaritime && arePassengersInMaritimeReservation) {
        fillNameFields(guests[0].firstName, guests[0].lastName)(change)
      }
    }
    this.props.fetchCountries()
  }

  fillContact() {
    fillContactDataForm(this.props.change)
  }

  fillCompanyContact() {
    fillCompanyDataForm(this.props.change)
  }

  render() {
    const { muiTheme } = this.context
    const {
      editMode,
      userDetails = {},
      allowedCountries = [],
      formMode,
      isVehicleDisplayed,
      isTablet,
      isMobile,
      locale,
    } = this.props

    const { firstName, lastName, phoneCode, phoneNumber, email } = userDetails

    const firstNameField = find(propEq('name', 'firstName'))(fields)
    const lastNameField = find(propEq('name', 'lastName'))(fields)
    const phoneCodeField = find(propEq('name', 'phoneCode'))(fields)
    const phoneNumberField = find(propEq('name', 'phoneNumber'))(fields)
    const emailField = find(propEq('name', 'email'))(fields)
    const companyNameField = find(propEq('name', 'legalName'))(fields)
    const companyNrField = find(propEq('name', 'registrationNumber'))(fields)
    const addressField = find(propEq('name', 'addressLine'))(fields)
    const codeField = find(propEq('name', 'zip'))(fields)
    const cityField = find(propEq('name', 'city'))(fields)
    const countryField = find(propEq('name', 'country'))(fields)

    const errorStyles = {
      'margin-top': '6px',
      'font-size': '12px',
      'font-family': muiTheme.secondFontFamily,
      ...getStyle({ textAlign: 'center' }),
    }

    const wrapperStyles = !isMaritime
      ? { padding: '0', ...muiTheme.ids.InputFieldWrapper }
      : {
          boxShadow: 'none !important',
          borderRadius: '4px',
          border: '1px solid #D4D4D4',
          position: 'relative',
          padding: '0',
          margin: '0 0 20px 0',
        }
    const lockedInputStyles = { padding: '1px 35px 0 14px', boxShadow: 'none' }
    const wrapperInputStyles = !isMaritime
      ? { padding: '20px 0 0 0', ...muiTheme.ids.InputFieldWrapperInput }
      : {
          height: 'auto',
          bgColor: 'white',
          borderRadius: '4px',
          boxShadow: 'none !important',
          padding: '0',
          position: 'static',
          margin: '0',
        }

    const phoneCodeWrapperStyles = !isMaritime
      ? {
          padding: getStyle('0 18px 0 0', '0'),
          maxWidth: getStyle('205px', '120px'),
          ...muiTheme.ids.InputFieldWrapper,
          background: 'salmon',
        }
      : {
          padding: getStyle('0 18px 0 0', '0'),
          maxWidth: getMaritimeStyle('80px', getStyle('205px', '120px')),
          ...muiTheme.ids.InputFieldWrapper,
          background: 'salmon',
          ...wrapperStyles,
          margin: '0 20px 20px 0',
        }
    const buttonBackgroundColor = muiTheme.palette.selectedItemSecondary
    const buttonBackgroundColorInactive = muiTheme.palette.unselectedItemSecondary

    const noAuthApp = Boolean(UIElements.NO_AUTH)

    const getColor = (color, defaultColor) => (window.brandProps.theme === THEME.LIINILAEVAD ? color : defaultColor)

    const liinilaevadInputStyles = {
      background: '#EDEDED',
      border: '1px solid #D4D4D4',
      borderRadius: '4px',
      boxShadow: 'none',
      padding: '4px 0 0 0',
    }

    const isArabic = locale === 'ar'

    return (
      <UILayout column width="100%" margin={getMaritimeStyle('0', '0 0 26px 0')} data-test="customer-info">
        <UILayout row center width="100%" sm={xsStyles.column} display-if={!this.props.editMode && !isMaritime}>
          <UILayout sm={xsStyles.titleWrapper}>
            <UIText
              font={window.brandProps.muiTheme.secondFontFamily}
              width="100%"
              size="20px"
              color={getColor(muiTheme.palette.primary4Color, '#000000')}
              align="left"
              translate={messages.customerInformation}
              sm={xsStyles.title}
              {...(muiTheme.ids.TicketTypeRow &&
                muiTheme.ids.TicketTypeRow.titles && {
                  ...muiTheme.ids.TicketTypeRow.titles,
                })}
            />
          </UILayout>
          <button style={fillButtonStyles} display-if={!isReallyProduction()} onClick={this.fillContact}>
            Fill random
          </button>
          <CustomerRole display-if={!noAuthApp} />
        </UILayout>

        <UILayout style={{ marginBlockEnd: '1rem', columnGap: '1rem' }} display-if={isMaritime}>
          <UIIcon
            type="iconCustomer_admaritime"
            fill={getBluewaveStyle('#0168A3', '#2D3955')}
            style={{ marginInlineEnd: '1rem' }}
          />
          <UIText
            font="ADPortsGroupBold, sans-serif"
            size="24px"
            color={getBluewaveStyle('#0168A3', '#2D3955')}
            translate={messages.customerInformation}
          />
          {!isReallyProduction() && (
            <button data-testid="fill-random-button" style={fillButtonMaritimeStyles} onClick={this.fillContact}>
              Fill random
            </button>
          )}
        </UILayout>

        <UILayout padding={getStyle('6px 0 0 0', '0 0 0 36px')} display-if={!isGavdos && !isMaritime}>
          <UILayout
            className="trailers-switcher"
            margin="16px 0 0 0"
            borderRadius="15px"
            style={{
              ...(buttonBackgroundColorInactive && {
                'background-color': buttonBackgroundColorInactive,
              }),
              ...(muiTheme.ids.trailersSwitcher && { ...muiTheme.ids.trailersSwitcher }),
              ...getStyle({
                height: '50px',
                borderRadius: '12px',
              }),
            }}
          >
            <button
              className={
                formMode !== 'private'
                  ? 'trailers-switcher__button'
                  : 'trailers-switcher__button trailers-switcher__button_active'
              }
              onClick={() => this.props.change('formMode', 'private')}
              style={{
                ...(buttonBackgroundColor &&
                  buttonBackgroundColorInactive && {
                    'background-color': formMode === 'private' ? buttonBackgroundColor : 'rgba(0,0,0,0)',
                    color: formMode === 'private' ? muiTheme.palette.accent1Color : muiTheme.palette.selectedItem,
                  }),
                ...getStyle({
                  fontFamily: 'SourceSansPro, Sans-Serif',
                  fontSize: '18px',
                  fontWeight: '700',
                  boxShadow: 'none',
                }),
              }}
            >
              {translate(messages.contactFormPrivate)}
            </button>
            <button
              className={
                formMode !== 'company'
                  ? 'trailers-switcher__button'
                  : 'trailers-switcher__button trailers-switcher__button_active'
              }
              onClick={() => this.props.change('formMode', 'company')}
              style={{
                ...(buttonBackgroundColor &&
                  buttonBackgroundColorInactive && {
                    'background-color': formMode === 'company' ? buttonBackgroundColor : 'rgba(0,0,0,0)',
                    color: formMode === 'company' ? muiTheme.palette.accent1Color : muiTheme.palette.selectedItem,
                  }),
                ...getStyle({
                  fontFamily: 'SourceSansPro, Sans-Serif',
                  fontSize: '18px',
                  fontWeight: '700',
                  boxShadow: 'none',
                }),
              }}
            >
              {translate(messages.contactFormCompany)}
            </button>
          </UILayout>
        </UILayout>

        <UILayout column center width="100%" data-test="customer-info-form">
          <UILayout
            row={getMaritimeStyle(!isVehicleDisplayed, getStyle(false, true))}
            column={getMaritimeStyle(isVehicleDisplayed, getStyle(true, false))}
            center
            width="100%"
            sm={xsStyles.column}
            {...muiTheme.ids.CustomerInfoDirections}
          >
            <UILayout
              data-testid="firstname-label-and-input"
              display-if={isMaritime}
              style={{ paddingInlineEnd: isVehicleDisplayed || isMobile || isTablet ? 0 : '1rem' }}
              className={ciStyles.labelAndInput}
            >
              <UILayout width="50%" padding="13px 0 0 0" position="relative">
                <UIText
                  size={getMaritimeStyle('13px')}
                  align={isArabic ? 'right' : 'left'}
                  color="#000000"
                  translate={messages.customerInfoNameLabel}
                />
                <span className={cn('star', moduleStyles.star, ciStyles.labelStar)}>*</span>
              </UILayout>
              <InputField
                key={firstNameField.name}
                {...firstNameField}
                icon={getMaritimeStyle('', getStyle('', firstNameField.icon))}
                showText
                required
                validate={[includeOnlyCorrectSymbols]}
                hideStar
                inputStyle={firstName ? lockedInputStyles : undefined}
                wrapperInputStyles={wrapperInputStyles}
                wrapperStyles={wrapperStyles}
                errorStyles={errorStyles}
                hideLabel
                data-test="customer-input-first-name"
                {...muiTheme.ids.InputField}
                disabled={firstName}
                locked={firstName}
              />
            </UILayout>

            <InputField
              display-if={!isMaritime}
              key={firstNameField.name}
              {...firstNameField}
              icon={getMaritimeStyle('', getStyle('', firstNameField.icon))}
              showText
              required
              hideStar
              inputStyle={firstName ? lockedInputStyles : undefined}
              wrapperInputStyles={wrapperInputStyles}
              wrapperStyles={wrapperStyles}
              errorStyles={errorStyles}
              hideLabel
              data-test="customer-input-first-name"
              {...muiTheme.ids.InputField}
              disabled={firstName}
              locked={firstName}
            />
            <UILayout {...muiTheme.ids.CustomerInfoDirections} className={ciStyles.labelAndInput}>
              <UILayout display-if={isMaritime} width="50%" padding="13px 0 0 0" position="relative">
                <UIText
                  size={getMaritimeStyle('13px')}
                  align={isArabic ? 'right' : 'left'}
                  color="#000000"
                  translate={messages.customerInfoSurnameLabel}
                />
                <span className={cn('star', moduleStyles.star, ciStyles.labelStar)}>*</span>
              </UILayout>
              <InputField
                key={lastNameField.name}
                {...lastNameField}
                required
                validate={[includeOnlyCorrectSymbols]}
                showText
                hideStar
                inputStyle={lastName ? lockedInputStyles : undefined}
                wrapperInputStyles={wrapperInputStyles}
                wrapperStyles={wrapperStyles}
                errorStyles={errorStyles}
                hideLabel
                data-test="customer-input-last-name"
                {...muiTheme.ids.InputField}
                disabled={lastName}
                locked={lastName}
              />
            </UILayout>
          </UILayout>

          <UILayout display-if={isMaritime} column={isVehicleDisplayed || isMobile || isTablet}>
            {(isMobile || isTablet) && (
              <UILayout
                className={moduleStyles.phoneNumberConfirmLbl}
                display-if={isMaritime}
                width={isMobile ? '82%' : '71%'}
                padding="13px 0 0 0"
                position="relative"
                xs={{ padding: '7px 0 0 0' }}
              >
                <UIText
                  size={getMaritimeStyle('13px')}
                  align={isArabic ? 'right' : 'left'}
                  color="#000000"
                  translate={messages.customerInfoMobilePhoneLabel}
                  xs={{ width: '70px' }}
                />
                <span className={cn('star', moduleStyles.star, ciStyles.labelStar)}>*</span>
              </UILayout>
            )}
            <UILayout
              row
              center={!isMaritime}
              width="100%"
              {...muiTheme.ids.CustomerInfoDirections}
              column={false}
              style={{ paddingInlineEnd: isVehicleDisplayed || isMobile || isTablet ? 0 : '1rem' }}
              className={moduleStyles.phoneNumberConfirmLayout}
            >
              {!(isMobile || isTablet) && (
                <UILayout
                  className={moduleStyles.phoneNumberConfirmLbl}
                  display-if={isMaritime}
                  width={isMobile ? '82%' : '71%'}
                  padding="13px 0 0 0"
                  position="relative"
                >
                  <UIText color="#000000" translate={messages.customerInfoMobilePhoneLabel} />
                  <span className={cn('star', moduleStyles.star, ciStyles.labelStar)}>*</span>
                </UILayout>
              )}
              <InputField
                key={phoneCodeField.name}
                {...phoneCodeField}
                icon={getMaritimeStyle('', getStyle('', phoneCodeField.icon))}
                required
                showText
                editMode={editMode}
                hideStar
                substitutionalLabelForLiinilaevad={messages[phoneNumberField.name]}
                wrapperInputStyles={wrapperInputStyles}
                wrapperStyles={phoneCodeWrapperStyles}
                errorStyles={errorStyles}
                hideLabel
                showPlus={getMaritimeStyle(false, getStyle(false, modernBrowser))}
                data-test="customer-input-phone-code"
                {...muiTheme.ids.InputField}
                disabled={phoneCode}
                onBlur={(e, newValue) => {
                  e.preventDefault()
                  this.props.change('phoneCode', newValue || defaultPhoneCode)
                }}
              />
              <InputField
                key={phoneNumberField.name}
                {...phoneNumberField}
                required
                hideStar
                wrapperInputStyles={wrapperInputStyles}
                wrapperStyles={wrapperStyles}
                errorStyles={errorStyles}
                hideLabel
                data-test="customer-input-phone-number"
                {...muiTheme.ids.InputField}
                disabled={phoneNumber}
                locked={phoneNumber}
              />
            </UILayout>
            <UILayout
              row
              center={!isMaritime}
              width="100%"
              {...muiTheme.ids.CustomerInfoDirections}
              className={ciStyles.labelAndInput}
            >
              <UILayout display-if={isMaritime} width="50%" padding="13px 0 0 0" position="relative">
                <UIText
                  size={getMaritimeStyle('13px')}
                  align={isArabic ? 'right' : 'left'}
                  color="#000000"
                  translate={messages.customerInfoEmailLabel}
                />
                <span className={cn('star', moduleStyles.star, ciStyles.labelStar)}>*</span>
              </UILayout>
              <InputField
                display-if={!editMode}
                key={emailField.name}
                {...emailField}
                icon={getMaritimeStyle('', getStyle('', emailField.icon))}
                required
                showText
                hideStar
                inputStyle={email ? lockedInputStyles : undefined}
                wrapperInputStyles={wrapperInputStyles}
                wrapperStyles={wrapperStyles}
                errorStyles={errorStyles}
                hideLabel
                data-test="customer-input-email"
                {...muiTheme.ids.InputField}
                disabled={email}
                locked={email}
              />
            </UILayout>
          </UILayout>

          <UILayout
            display-if={!isMaritime}
            row
            center
            width="100%"
            {...muiTheme.ids.CustomerInfoDirections}
            column={false}
          >
            <InputField
              key={phoneCodeField.name}
              {...phoneCodeField}
              icon={getStyle('', phoneCodeField.icon)}
              required
              showText
              editMode={editMode}
              hideStar
              substitutionalLabelForLiinilaevad={messages[phoneNumberField.name]}
              wrapperInputStyles={wrapperInputStyles}
              wrapperStyles={phoneCodeWrapperStyles}
              errorStyles={errorStyles}
              hideLabel
              showPlus={getStyle(false, modernBrowser)}
              data-test="customer-input-phone-code"
              {...muiTheme.ids.InputField}
              disabled={phoneCode}
              onBlur={(e, newValue) => {
                e.preventDefault()
                this.props.change('phoneCode', newValue || defaultPhoneCode)
              }}
            />
            <InputField
              key={phoneNumberField.name}
              {...phoneNumberField}
              required
              hideStar
              wrapperInputStyles={wrapperInputStyles}
              wrapperStyles={wrapperStyles}
              errorStyles={errorStyles}
              hideLabel
              data-test="customer-input-phone-number"
              {...muiTheme.ids.InputField}
              disabled={phoneNumber}
              locked={phoneNumber}
            />
          </UILayout>
          <UILayout display-if={!isMaritime} row center width="100%" {...muiTheme.ids.CustomerInfoDirections}>
            <InputField
              display-if={!editMode}
              key={emailField.name}
              {...emailField}
              icon={getStyle('', emailField.icon)}
              required
              showText
              hideStar
              inputStyle={email ? lockedInputStyles : undefined}
              wrapperInputStyles={wrapperInputStyles}
              wrapperStyles={wrapperStyles}
              errorStyles={errorStyles}
              hideLabel
              data-test="customer-input-email"
              {...muiTheme.ids.InputField}
              disabled={email}
              locked={email}
            />
          </UILayout>
        </UILayout>

        <UILayout column center width="100%" data-test="company-info-form" display-if={formMode === 'company'}>
          <UILayout padding={getStyle('6px 0 0 0', '0 0 0 36px')} column>
            <button
              style={{ ...fillButtonStyles, margin: '1rem auto', fontSize: '0.9em' }}
              display-if={!isReallyProduction()}
              onClick={this.fillCompanyContact}
            >
              Fill Company Data
            </button>
            <UILayout row center width="100%" {...muiTheme.ids.CustomerInfoDirections}>
              <InputField
                key={companyNameField.name}
                {...companyNameField}
                required
                showText
                hideStar
                wrapperInputStyles={wrapperInputStyles}
                wrapperStyles={wrapperStyles}
                errorStyles={errorStyles}
                hideLabel
                data-test="customer-input-company-name"
                {...muiTheme.ids.InputField}
              />
            </UILayout>

            <UILayout row center width="100%" {...muiTheme.ids.CustomerInfoDirections}>
              <InputField
                key={companyNrField.name}
                {...companyNrField}
                required
                hideStar
                showText
                wrapperInputStyles={wrapperInputStyles}
                wrapperStyles={wrapperStyles}
                errorStyles={errorStyles}
                hideLabel
                data-test="customer-input-company-regnumber"
                {...muiTheme.ids.InputField}
              />
            </UILayout>

            <UILayout column={getStyle(true, false)}>
              <UILayout
                row
                center
                width="70%"
                {...muiTheme.ids.CustomerInfoDirections}
                padding={getStyle(muiTheme.ids.CustomerInfoDirections.padding, '0 9px 0 0')}
              >
                <InputField
                  key={addressField.name}
                  {...addressField}
                  required
                  showText
                  hideStar
                  wrapperInputStyles={wrapperInputStyles}
                  wrapperStyles={wrapperStyles}
                  errorStyles={errorStyles}
                  hideLabel
                  data-test="customer-input-company-address"
                  {...muiTheme.ids.InputField}
                />
              </UILayout>

              <UILayout
                row
                center
                width="30%"
                {...muiTheme.ids.CustomerInfoDirections}
                padding={getStyle(muiTheme.ids.CustomerInfoDirections.padding, '0 9px 0 0')}
              >
                <InputField
                  key={codeField.name}
                  {...codeField}
                  required
                  hideStar
                  showText
                  wrapperInputStyles={wrapperInputStyles}
                  wrapperStyles={wrapperStyles}
                  errorStyles={errorStyles}
                  hideLabel
                  data-test="customer-input-company-postindex"
                  {...muiTheme.ids.InputField}
                />
              </UILayout>
            </UILayout>

            <UILayout column={getStyle(true, false)}>
              <UILayout
                row
                center
                width="50%"
                {...muiTheme.ids.CustomerInfoDirections}
                padding={getStyle(muiTheme.ids.CustomerInfoDirections.padding, '0 9px 0 0')}
              >
                <InputField
                  key={cityField.name}
                  {...cityField}
                  required
                  hideStar
                  showText
                  wrapperInputStyles={wrapperInputStyles}
                  wrapperStyles={wrapperStyles}
                  errorStyles={errorStyles}
                  hideLabel
                  data-test="customer-input-company-city"
                  {...muiTheme.ids.InputField}
                />
              </UILayout>

              <UILayout
                row
                center
                width="50%"
                {...muiTheme.ids.CustomerInfoDirections}
                {...getStyle({ column: false, alignItems: 'center' })}
                padding={getStyle(muiTheme.ids.CustomerInfoDirections.padding, '20px 0 0 9px')}
              >
                {window.brandProps.theme === THEME.LIINILAEVAD && (
                  <UIText
                    color="#000000"
                    width="145px"
                    font="SourceSansPro, Sans-Serif"
                    size="16px"
                    align="left"
                    padding="20px 0 0 0"
                    translate={messages[countryField.name]}
                  />
                )}

                <DropDownField
                  name="country"
                  required
                  styles={{ padding: getStyle('20px 0 0 0', '0'), width: '100%' }}
                  inputStyles={getStyle(liinilaevadInputStyles)}
                  labelContainerProps={{ display: 'none' }}
                  inputContainerProps={{ padding: 0 }}
                  itemsArr={allowedCountries}
                  itemTemplate={CompanyItemTemplate}
                  selectionTemplate={CompanyItemTemplate}
                />
              </UILayout>
            </UILayout>
          </UILayout>
        </UILayout>
      </UILayout>
    )
  }
}

CustomerInfo.propTypes = {
  fields: PropTypes.array.isRequired,
  editMode: PropTypes.bool,
  userDetails: PropTypes.object.isRequired,
  fetchCountries: PropTypes.func.isRequired,
  allowedCountries: PropTypes.array.isRequired,
  change: PropTypes.func.isRequired,
}

CustomerInfo.defaultProps = {
  editMode: false,
  formMode: FORM_MODE_PRIVATE,
}

CustomerInfo.contextTypes = {
  muiTheme: PropTypes.any,
}

export default CustomerInfo
